import "./index.css";

import * as React from "react";
import { StaticImage } from "gatsby-plugin-image";
import { Trans, useTranslation } from "react-i18next";
import { graphql } from "gatsby";
import { CSSTransition, SwitchTransition } from "react-transition-group";
import { Link } from "gatsby-plugin-react-i18next";

import Layout from "../components/layout/Layout";
import Seo from "../components/seo";
// import LandingTimelapse from "../components/layout/LandingTimelapse";

import Logo from "../svg/begin-logo-line.svg";
import Subrayado3 from "../svg/subrayados/subrayado03.svg";
import Subrayado2 from "../svg/subrayados/subrayado02.svg";

export default function IndexPage() {
	const { t } = useTranslation();

	const windowGlobal = typeof window !== "undefined" && window;

	const [intro, setIntro] = React.useState(() => {
		// return true
		if (windowGlobal) {
			return windowGlobal.sessionStorage.getItem("intro") || true;
		}
	});

	React.useEffect(() => {
		if (intro === true) {
			setTimeout(() => {
				setIntro(false);
				sessionStorage.setItem("intro", false);
			}, 3000);
		}
	}, [intro]);

	return (
		<Layout hideTopLogo={intro === true} noSections={true}>
			<Seo title={t("Home")} />

			<div className="landingBackgrounds" id="landingBackground">
				<div className="blackOverlay landing" />
				<StaticImage
					src={`../images/landing.jpg`}
					className="landing-bg"
					alt="Section background"
					layout="fullWidth"
				/>
				{/* <LandingTimelapse intro={intro} /> */}
			</div>

			<div className="landingBody">
				<SwitchTransition mode="out-in">
					<CSSTransition key={`Intro${intro}`} timeout={400} classNames="fade">
						{intro !== true ? (
							<>
								<h1 style={{ animation: "none" }} className="experienciaTitle">
									<Trans i18nKey="titleLanding">
										Vuelve <i>al origen</i>
									</Trans>
								</h1>
								<div id="opcionesExperiencia">
									<Link to="/reserva">
										<h2 className="opcionText">
											<Trans>Reservar</Trans>
											<Subrayado2 />
										</h2>
									</Link>
									<Link to="/menus#sectionContent2">
										<h2 className="opcionText">
											<Trans>Ver cartas</Trans>
											<Subrayado3 />
										</h2>
									</Link>
									{/* <a
										href="https://linktr.ee/begintakeaway"
										target="_blank"
										rel="noopener noreferrer"
									>
										<h2 className="opcionText">
											<Trans>Recoger en local</Trans>
											<Subrayado4 />
										</h2>
									</a> */}
									{/* <a
										href="https://linktr.ee/beginrestaurante"
										target="_blank"
										rel="noopener noreferrer"
										aria-label={t("Pedir a domicilio")}
									>
										<h2 className="opcionText">
											<Trans>Pedir a domicilio</Trans>
											<Subrayado4 />
										</h2>
									</a> */}
								</div>
							</>
						) : (
							<>
								<Logo className="logoLanding" />
								<div className="subtitleLanding" id="subtitleLanding">
									<h2>
										<Trans>Vuelve al origen</Trans>
									</h2>
								</div>
							</>
						)}
					</CSSTransition>
				</SwitchTransition>
			</div>
		</Layout>
	);
}

export const query = graphql`
	query ($language: String!) {
		locales: allLocale(filter: { language: { eq: $language } }) {
			edges {
				node {
					ns
					data
					language
				}
			}
		}
	}
`;
